import { defineStore } from 'pinia';
import { useFundStore } from '@stores/useFundStore';
import { FUND_DRAWS } from '@graphql/queries/fund';
import { FundDrawStatuses } from '@graphql/enums/fundDrawStatuses';
import { OperationResult } from '@urql/core';
import { useUrqlStore } from '@stores/useUrqlStore';

import type { FundDrawsResponse, FundDraw } from '@graphql/types/fund';
import { useAuthStore } from './useAuthStore';

interface FundDraws {
  data: FundDraw[] | undefined;
  error: boolean;
  fetching: boolean;
  currentFundDraws: FundDraw[] | undefined;
}

export const storeSetup = () => {
  const { gqlClient } = toRefs(useUrqlStore());

  const fundDraws: FundDraws = reactive({
    data: undefined,
    error: false,
    fetching: false,
    currentFundDraws: undefined,
  });

  const currentFundDraws = computed(() => fundDraws.currentFundDraws);

  const getFundDraws = async (): Promise<void> => {
    const { accessToken } = toRefs(useAuthStore());
    fundDraws.fetching = true;
    fundDraws.data = [];

    await gqlClient.value.query<FundDrawsResponse>(FUND_DRAWS, {}, {
      requestPolicy: 'network-only',
      fetchOptions: {
        headers: {
          authorization: `Bearer ${accessToken.value}`,
        },
      },
    })
      .toPromise()
      .then((result: OperationResult<FundDrawsResponse>) => {
        if (result.data && result.data.fundDraws.length > 0) {
          fundDraws.data = result.data.fundDraws;
        }

        if (result.error) {
          // eslint-disable-next-line unicorn/error-message
          throw new Error();
        }
      })
      .catch(() => {
        fundDraws.error = true;
      })
      .finally(() => {
        fundDraws.fetching = false;
      });
  };

  const getCurrentFundDraws = async () => {
    const { currentFund } = toRefs(useFundStore());

    if (currentFund.value) {
      fundDraws.fetching = true;
      fundDraws.currentFundDraws = [];
      await gqlClient.value.query<FundDrawsResponse>(FUND_DRAWS, { fund_id: currentFund.value.id })
        .toPromise()
        .then((result: OperationResult<FundDrawsResponse>) => {
          if (result.data && result.data.fundDraws.length > 0) {
            fundDraws.currentFundDraws = result.data.fundDraws;
          }

          if (result.error) {
          // eslint-disable-next-line unicorn/error-message
            throw new Error();
          }
        })
        .catch(() => {
          fundDraws.error = true;
        })
        .finally(() => {
          fundDraws.fetching = false;
        });
    }
  };

  const setCurrentDrawStatus = () => {
    if (fundDraws.currentFundDraws) {
      fundDraws.currentFundDraws[0].status = FundDrawStatuses.CHECKING;
    }
  };

  const shouldSign = computed(
    () => currentFundDraws.value?.find(
      draw => draw.status === FundDrawStatuses.CONCEPT || draw.status === FundDrawStatuses.CHECKING,
    ),
  );

  const shouldPayOrPayed = computed(
    () => currentFundDraws.value?.find(
      draw => draw.status === FundDrawStatuses.WAITING
        || draw.status === FundDrawStatuses.PAYMENT_RECEIVED
        || draw.status === FundDrawStatuses.ACTIVE,
    ),
  );

  const getFundDrawsById = async (fund_id: string): Promise<FundDraw[]> => {
    return new Promise((resolve, reject) => {
      const { accessToken } = toRefs(useAuthStore());
      fundDraws.fetching = true;
      fundDraws.currentFundDraws = [];

      gqlClient.value.query<FundDrawsResponse>(FUND_DRAWS, { fund_id }, {
        requestPolicy: 'network-only',
        fetchOptions: {
          headers: {
            authorization: `Bearer ${accessToken.value}`,
          },
        },
      })
        .toPromise()
        .then((result: OperationResult<FundDrawsResponse>) => {
          if (result.data && result.data.fundDraws) {
            resolve(result.data.fundDraws);
          }

          if (result.error) {
            reject();
            throw new Error(result.error.message);
          }
        })
        .catch(() => {
          reject();
          fundDraws.error = true;
        })
        .finally(() => {
          fundDraws.fetching = false;
        });
    });
  };

  return {
    fundDraws,
    currentFundDraws,
    shouldSign,
    shouldPayOrPayed,
    getFundDrawsById,
    getCurrentFundDraws,
    getFundDraws,
    setCurrentDrawStatus,
  };
};

export const useFundDrawsStore = defineStore('funddraws', storeSetup, {
  persistedState: {
    persist: false,
  },
});
