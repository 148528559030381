import { gql } from '@urql/core';

export const UPDATE_FUND_STATUS = gql`
  mutation ($input: fundstatusupdateinput!) {
    updatefundstatus(input: $input) {
      message
      status
    }
  }
`;

export const FUND_REGISTER = gql`
  mutation ($input: FundRegisterInput!) {
    fundRegister(input: $input) {
      id
      fund_id
      investor_type
      investor {
        ... on Person {
          id
          name
          __typename
        }
        ... on Company {
          id
          name
          coc_number
          __typename
        }
      }
      status
      signed_name
      fund_total_investment
      commitment_agreement_signed
      reason
      iban
      iban_name
      bic
    }
  }
`;

export const DELETE_REGISTRATION = gql`
  mutation ($input: FundRegisterDeleteInput!) {
    deleteFundRegister(input: $input) {
      id
      fund_id
      investor_type
      investor {
        ... on Person {
          id
          name
        }
        ... on Company {
          id
          name
          coc_number
          __typename
        }
      }
      status
      signed_name
      fund_total_investment
      commitment_agreement_signed
      reason
      iban
      iban_name
      bic
    }
  }
`;
