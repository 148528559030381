export enum FundDrawStatuses {
  CONCEPT = 'CONCEPT',
  ACTIVE = 'ACTIVE',
  WAITING = 'WAITING',
  CHECKING = 'CHECKING',
  PAYMENT_RECEIVED = 'PAYMENT_RECEIVED',
  STOPPED = 'STOPPED',
}

export enum FundType {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN'
}
