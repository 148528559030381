/* eslint-disable @typescript-eslint/no-unused-vars */
import { reactive } from 'vue';
import { defineStore } from 'pinia';
import { OperationResult } from '@urql/core';
import { useAuthStore } from '@stores/useAuthStore';
import { FundRegistrationStatus, FundInvestor } from '@graphql/enums/fundRegister';
import { FUND_REGISTRATIONS } from '@graphql/queries/fund';
import type {
  FundRegistration,
  FundRegistrationResponse,
  FundRegistrationsResponse,
  PersonFundRegistration,
  BusinessFundRegistration,
  DeleteFundRegistrationResponse,
} from '@graphql/types/fund';
import { DELETE_REGISTRATION, FUND_REGISTER } from '@graphql/mutations/fund';
import { useUrqlStore } from '@stores/useUrqlStore';

interface FundRegistrations {
  data: FundRegistration[] | undefined;
  currentFundRegistration: FundRegistration | null | undefined;
  error: boolean;
  fetching: boolean;
  privateRegistrations: PersonFundRegistration[];
  businessRegistrations: BusinessFundRegistration[];
  inputFundRegistration: FundRegistration | null | undefined;
}


export const storeSetup = () => {
  const { gqlClient } = toRefs(useUrqlStore());

  const auth = useAuthStore();

  const fundRegistrations: FundRegistrations = reactive({
    data: undefined,
    error: false,
    fetching: false,
    privateRegistrations: [],
    businessRegistrations: [],
    currentFundRegistration: null,
    inputFundRegistration: null,
  });

  const getFundRegistrations = async () => {
    if (auth.accessToken) {
      fundRegistrations.fetching = true;

      await gqlClient.value
        .query<FundRegistrationsResponse>(FUND_REGISTRATIONS, undefined)
        .toPromise()
        .then((result: OperationResult<FundRegistrationsResponse>) => {
          fundRegistrations.data = result.data?.fundRegistrations;
          fundRegistrations.error = false;
        })
        .catch(() => {
          showError({
            statusCode: 404,
            message: 'Deze pagina bestaat niet',
          });
        })
        .finally(() => {
          fundRegistrations.fetching = false;
        });
    }

    if (!auth.isLoading && !auth.authenticated) {
      fundRegistrations.data = undefined;
    }
  };

  const deleteFundRegistration = async (registration_id: number) => {
    return new Promise((resolve, reject) => {
      if (auth.accessToken) {
        gqlClient.value
          .mutation<DeleteFundRegistrationResponse>(
            DELETE_REGISTRATION,
            {
              input: {
                registration_id: Number(registration_id),
              },
            },
          )
          .toPromise()
          .then((result: OperationResult<DeleteFundRegistrationResponse>) => {
            fundRegistrations.data = result.data?.deleteFundRegister;
            resolve(result.data);
          })
          .catch(error => {
            reject(error);
          });
      }
    });
  };

  const addOrEditFundRegistration = async (investorType: FundInvestor, fundId: string) => {
    let investment: string | number | undefined = fundRegistrations.inputFundRegistration?.fund_total_investment.toString().replace(/[^\s\w]/gi, '');
    // eslint-disable-next-line radix
    investment = investment ? parseInt(investment) : 0;

    const input = {
      coc_number: fundRegistrations.inputFundRegistration?.coc_number,
      fund_total_investment: investment,
      iban_name: fundRegistrations.inputFundRegistration?.iban_name,
      iban: fundRegistrations.inputFundRegistration?.iban,
      investor_type: investorType,
      // eslint-disable-next-line radix
      fund_id: parseInt(fundId),
      registration_id: Number(fundRegistrations.currentFundRegistration?.id),
    };

    return new Promise((resolve, reject) => {
      if (auth.accessToken) {
        gqlClient.value
          .mutation<FundRegistrationResponse>(
            FUND_REGISTER,
            {
              input,
            },
          )
          .toPromise()
          .then((result: OperationResult<FundRegistrationResponse>) => {
            getFundRegistrations();
            resolve(result.data);
          })
          .catch(error => {
            reject(error);
          });
      }
    });
  };

  const setFundRegistrations = (registrations: FundRegistration[]) => {
    fundRegistrations.data = registrations;
  };

  const canShowRegistration = (registration: FundRegistration) => (registration
    ? registration.status !== FundRegistrationStatus.STOPPED &&
        registration.status !== FundRegistrationStatus.INVESTOR_STOPPED &&
        registration.status !== FundRegistrationStatus.INACTIVE
    : false);

  const getFundRegistrationById = (id: string) => {
    if (!fundRegistrations.data?.length) return [];

    return fundRegistrations.data
      .filter(registration => registration.fund_id === parseInt(id, 10))
      .filter(currentRegistration => canShowRegistration(currentRegistration));
  };

  const hasIncompleteRegistrations = (id: string) => {
    const currentRegistrations = getFundRegistrationById(id);

    if (!currentRegistrations || currentRegistrations?.length === 0) return true;
    return !!currentRegistrations?.find(
      registration => !registration.iban || !registration.iban_name || registration.fund_total_investment === 0,
    );
  };

  const getPrivateRegistrations = (id: string) => {
    const currentRegistrations = getFundRegistrationById(id);
    const personRegistrations = (currentRegistrations?.filter(registration => registration.investor_type === FundInvestor.PERSON) || []) as PersonFundRegistration[];
    fundRegistrations.privateRegistrations = personRegistrations;
    return fundRegistrations.privateRegistrations;
  };

  const getBusinessRegistrations = (id: string) => {
    const currentRegistrations = getFundRegistrationById(id);
    const companyRegistrations = (currentRegistrations?.filter(registration => registration.investor_type === FundInvestor.BUSINESS) || []) as BusinessFundRegistration[];
    fundRegistrations.businessRegistrations = companyRegistrations;
    return fundRegistrations.businessRegistrations;
  };

  const getTotalInvestment = (id: string) => {
    const currentRegistrations = getFundRegistrationById(id);
    return currentRegistrations?.reduce((prev, next) => prev + next.fund_total_investment, 0);
  };

  const addFundRegistration = (registration: FundRegistration) => {
    fundRegistrations.data?.push(registration);
  };

  const updateFundRegistration = (registration: FundRegistration) => {
    fundRegistrations.data = fundRegistrations.data?.map(currentRegistration => {
      return currentRegistration.id === registration.id ? registration : currentRegistration;
    });
  };

  const setCurrentFundRegistration = (registration: FundRegistration | undefined | null) => {
    fundRegistrations.currentFundRegistration = registration;
  };

  const setInputFundRegistration = (value: unknown) => {
    fundRegistrations.inputFundRegistration = value as FundRegistration;
  };

  const clearInputFundRegistration = () => {
    fundRegistrations.inputFundRegistration = null;
  };

  const currentFundRegistration = computed(() => fundRegistrations.currentFundRegistration);
  const inputFundRegistration = computed(() => fundRegistrations.inputFundRegistration);

  return {
    fundRegistrations,
    setFundRegistrations,
    setCurrentFundRegistration,
    getBusinessRegistrations,
    getPrivateRegistrations,
    getTotalInvestment,
    getFundRegistrations,
    getFundRegistrationById,
    hasIncompleteRegistrations,
    updateFundRegistration,
    deleteFundRegistration,
    addFundRegistration,
    currentFundRegistration,
    addOrEditFundRegistration,
    setInputFundRegistration,
    inputFundRegistration,
    clearInputFundRegistration,
  };
};

export const useFundRegistrationsStore = defineStore('fundRegistrations', storeSetup, {
  persistedState: {
    persist: false,
  },
});
