export enum FundInvestor {
  PERSON = 'PERSON',
  BUSINESS = 'BUSINESS',
}

export enum FundRegistrationStatus {
  INVESTOR_STOPPED = 'INVESTOR_STOPPED',
  INACTIVE = 'INACTIVE',
  PROSPECT = 'PROSPECT',
  CONCEPT = 'CONCEPT',
  INTERESTED = 'INTERESTED',
  KYC_PENDING = 'KYC_PENDING',
  KYC_COMPLETE = 'KYC_COMPLETE',
  ACTIVE = 'ACTIVE',
  STOPPED = 'STOPPED',
  CONTROL_BY_COMPLIANCE = 'CONTROL_BY_COMPLIANCE',
}
